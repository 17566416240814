<template>
  <div class="calendar__row" :class="{ header__table: isHeaderTable }">
    <div v-for="number in columns + 1"
         :key="number-1"
         :class="numberClass(number-1)"
    >
      <v-tooltip v-if="milestones.includes(number - 1)" right color="white" max-width="50vw">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" class="check--icon">mdi-check</v-icon>
        </template>
        <div v-html="tooltipTitle(number-1)"/>
      </v-tooltip>
      <span v-if="displayDayNumber(number-1)">{{ number - 1 }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CalendarRow',

  props: {
    columns: {
      required: true,
      type: Number,
    },
    daysInThePast: {
      required: true,
      type: Number,
      default: 0,
    },
    isHeaderTable: {
      required: false,
      type: Boolean,
      default: false,
    },
    milestones: {
      required: true,
      type: Array,
    },
    statuses: {
      required: false,
      type: Object,
    },
    code: {
      required: false,
      type: String,
    },
  },

  computed: {
    ...mapGetters([
      'surveyResults',
    ]),
  },

  methods: {
    displayDayNumber (dayNumber) {
      return this.isHeaderTable && (dayNumber === 0 || dayNumber % 7 === 0)
    },
    tooltipTitle (dayNumber) {
      if (this.surveyResults[this.code]
          && this.surveyResults[this.code][dayNumber]
          && this.surveyResults[this.code][dayNumber].scores
          && Object.keys(this.surveyResults[this.code][dayNumber].scores).length) {
        let listContainer = '<ul>'
        Object.keys(this.surveyResults[this.code][dayNumber].scores).forEach(key => {
          let realKey = key.match(/\{V:([\w-]+)\}/)
          if(realKey && realKey[1]) {
            listContainer += `<li>${realKey[1]} : ${this.surveyResults[this.code][dayNumber].scores[key]}</li>`
          }
        })
        listContainer += '</ul>'
        return listContainer
      }

      return 'Pas de résultat enregistré'
    },
    numberClass (dayNumber) {
      if (dayNumber === this.daysInThePast - 1) {
        return 'today'
      }

      if (dayNumber >= this.daysInThePast) {
        return ''
      }

      if (dayNumber < this.daysInThePast && (this.statuses === undefined || this.statuses[dayNumber] === 2)) {
        return 'past_day'
      }

      if (dayNumber < this.daysInThePast && this.statuses && this.statuses[dayNumber] < 2) {
        return 'incomplete'
      }

      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.calendar__row {
  display: flex;
  min-height: 35px;
  box-sizing: border-box;
  width: 100%;

  > div {
    display: flex;
    justify-content: center;
    width: 22px;
    box-sizing: border-box;
    border-bottom: 1px solid black;
    border-right: 1px solid black;

    &.past_day {
      background-color: rgba(24, 203, 0, 0.4);
    }

    &.incomplete {
      background-color: rgba(203, 24, 0, 0.4);
    }

    &.today {
      background-color: rgba(79, 90, 180, 0.4);
    }

    i {
      font-size: 20px;
      cursor: pointer;
    }
  }

  &.header__table {
    color: white;
    background-color: #121212;

    > div {
      border-top: 1px solid white;
      background-color: #121212;
      border-color: white;
      color: inherit;

      &.past_day {
        background-color: rgba(24, 203, 0, 0.4);
      }

      &.today {
        background-color: rgba(79, 90, 180, 0.4);
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: inherit;
      }
    }
  }
}
</style>
